<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_ThongKeBXCoPM_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <div
                class="row justify-space-between align-center"
                style="border-bottom: 1px solid #ddd"
            >
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center pt-2 pb-4">
                        <span
                            class="mr-2 font-16"
                            id="label_THCuaXeTheoThang_Tu"
                            >Chọn tháng</span
                        >
                        <DxDateBox
                            v-model="model.DateFrom"
                            type="date"
                            displayFormat="MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            id="datebox_THCuaXeTheoThang_TuNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxCalendarOptions
                                max-zoom-level="year"
                                min-zoom-level="decade"
                                zoom-level="year"
                            />
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            class="ml-3"
                            :icon="$i18n.t('icon.search')"
                            text="Lọc"
                            styling-mode="outlined"
                            :rtlEnabled="true"
                            id="button_THCuaXeTheoThang_Loc"
                            @click="validate"
                        />
                    </div>
                </DxValidationGroup>
                <div class="pr-3">
                    <div class="row align-center">
                        <span>Ghi chú:</span>
                        <i
                            class="mdi mdi-check-circle-outline"
                            style="
                                color: #13b000;
                                padding-right: 5px;
                                padding-left: 10px;
                            "
                        ></i>
                        <span>Có truyền tải</span>
                        <i
                            class="mdi mdi-close-circle-outline"
                            style="
                                color: #d10909;
                                padding-right: 5px;
                                padding-left: 10px;
                            "
                        ></i>
                        <span>Không truyền tải</span>
                        <i
                            class="mdi mdi-alert-circle-outline"
                            style="
                                color: #fb8c00;
                                padding-right: 5px;
                                padding-left: 10px;
                            "
                        ></i>
                        <span>Đang khắc phục</span>
                    </div>
                </div>
            </div>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <datatable-vue :Params="data_from_api" />
        </div>
    </div>
</template>
<script>
import router from "@/components/_Common/Components/router";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/TrangThaiTruyenTaiDuLieuCuaBenXe/Datatable";
import ToolbarVue from "@/components/BaoCaoThongKe/TrangThaiTruyenTaiDuLieuCuaBenXe/Toolbar";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxCalendarOptions } from "devextreme-vue/date-box";
import { DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import moment from "moment";

export default {
    // Permission: $PermText("Danh Sách", "Báo cáo trạng thái truyền tải dữ liệu"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        searchVue,
        DatatableVue,
        DxToolbar,
        DxItem,
        DxValidationGroup,
        DxDateBox,
        DxCalendarOptions,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxButton,
        DxValidationGroup,
    },
    data() {
        return {
            url: this.$i18n.t("url.TrangThaiTruyenTaiMoi"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            url_dsben: this.$i18n.t("url.DungChungDanhSachBenTheoSo"),
            url_select_trangthai: this.$i18n.t(
                "url.BCTrangThaiTruyenTaiTrangThai"
            ),
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "idBen",
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
                Dialog: false,
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            dateValue: new Date().getTime(),
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectNhom: [],
                    SelectSo: [],
                    SelectLoaiBX: [],
                },
                DataBen: [],
                DataTable: null,
                Date: null,
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_ThongKeBenXeCoPM_BCHoatDongCuaBenXe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_ThongKeBenXeCoPM_ThongKeBenXeCoPM",
                    text: this.$i18n.t("stringRouter.brancheTKBXCPM"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let query = {
                    thang: new Date(this.model.DateFrom).getMonth() + 1,
                    nam: new Date(this.model.DateFrom).getFullYear(),
                    Idso: this.model.IdSos,
                    GUIDCaiDat: this.$i18n.t(
                        "DefaultNumBer.GUIDCaiDatBCTrangThaiTT"
                    ),
                    txtTimKiem: this.model.Search || "",
                };

                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_BaoCaoTrangThaiTruyenTaiDuLieuBenXe",
                        query
                    )
                ).Store;
                this.data_from_api.Date = moment(this.model.DateFrom).format(
                    "MM/DD/yyyy"
                );

                this.getDaysInMonth();
                this.getDataBenTheoSo();
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 236 ~ getData ~ error",
                    error
                );
            }
        },
        async getDataBenTheoSo() {
            try {
                // this.$startLoading;
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url_dsben, {
                    idSos: this.model.IdSos || 0,
                }).Get();
                if (rs.Data.State == "Success") {
                    let data = rs.Data.Data?.filter(
                        ({ IdBen }) => IdBen != 0
                    ).map(({ IdBen, TenBenXe, Value = [] }) => ({
                        text: TenBenXe,
                        value: ["idBen", "=", IdBen],
                    }));
                    this.data_from_api.DataBen = data;
                }
            } catch (error) {
                console.log("🚀 error", error);
            } finally {
                // this.$stopLoading;
            }
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
            };
            this.getData();
        },
        searchs(search) {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdLoaiBens = data.SelectLoaiBX;
            this.model.IdSos = data.SelectSo;
            console.log(
                "selectionChange -> this.model.IdSos",
                this.model.IdSos
            );
        },
        validate(e) {
            let result = this.$refs.formValidation.instance.validate();
            if (!!result.isValid) {
                this.getData();
            }
        },
        getDaysInMonth() {
            let number = new Date(
                new Date(this.model.DateFrom).getFullYear(),
                new Date(this.model.DateFrom).getMonth() + 1,
                0
            ).getDate();
            let month = moment(this.model.DateFrom).format("MM/yyyy");
            this.emitter.emit("days-in-month", { number, month });
        },
    },
    watch: {},
    async created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
        // this.getData();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
>>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
}
</style>